import React,{useState, useEffect,useRef, useCallback} from 'react';
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns';
import { useHistory } from "react-router-dom";
import HomeSlider from './HomeSlider';
import Top from './Top';
import HeadingTab from './HeadingTab';
import TopVideoCrow from './TopVideoCrow';

// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css'; // Import Calendar CSS
import VideoCatList from './VideoCatList';
import Modal from 'react-bootstrap/Modal';
import FileSaver from "file-saver";
import VideoCatListShort from './VideoCatListShort'

const VideoWire = ()=>{
const [cateData, setCateData] = useState({})
const [searchHead, SetSHead] = useState('template-search');

const [engData, setEngData] = useState()
const categoryList = [
    {
        name:'National',
        slug:'national'
    },
    {
        name:'Entertainment',
        slug:'entertainment'
    },
    {
        name:'Science/Tech',
        slug:'science-tech'
    },
    {
        name:'Sports',
        slug:'sports'
    },
    // ,
    // {
    //     name:'T20 World Cup 2024',
    //     slug:'t20worldcup2024'
    // }
    // {
    //     name:'Paris Olympics 2024',
    //     slug:'olympics2024'
    // }        
]   
const [cateText, setCategoryName] = useState('all')
const [dateValue, setDateValue] = useState('');

const [cateName, setCateName] = useState('')
const [searchText, setSearchText] = useState('')
const [inputSearch, setSearch] = useState()
const inputRef = useRef(null)
const {categoryId, search, offset,} = useParams()
const [currentPage, setCurrentPage] = useState(1)
const [pageList, setPageList] = useState([1,2,3])
const [lastPage, setLastPage] = useState(1)
const [lt, setLt] = useState(5)
const history = useHistory()
const [requestFlag, setRequestFlag] = useState(true)
const [langg, setLanguagge] = useState('')
const [latData, setLatestData] = useState()
const [topData, setTopData] = useState()
const [selectedDate, setSelectedDate] = useState(new Date());

const [durations, setDurations] = useState({});
  const [isVideoLoaded, setIsVideoLoaded] = useState({});

  const handleLoadedMetadata = (event, id) => {
    const videoDuration = event.target.duration;
    const minutes = Math.floor(videoDuration / 60).toString().padStart(2, '0');
    const seconds = Math.floor(videoDuration % 60).toString().padStart(2, '0'); // Add leading zero if needed
    const formattedDuration = `${minutes}:${seconds}`;
    setDurations((prevDurations) => ({
        ...prevDurations,
        [id]: formattedDuration
    }));
};
const onChange = (date) => {
  const updateDte = getDateFormate(date)
  
  setSelectedDate(updateDte)
  SetSearchDateVal(updateDte)
}
const handleShow = (url, slug, vId, vDu) => {
    // if(!localStorage || localStorage.length == 0 || !localStorage.accessToken || !localStorage.videoEngStatus || localStorage.videoEngStatus=='false' || localStorage.videoEngStatus == false){
    //     history.push('/permision')
        if(!localStorage || localStorage.length == 0 || !localStorage.accessToken){
            localStorage.setItem('redirectTo','video-wire/short_videos/1');
            localStorage.setItem('langee', 'video');
            history.push('/login');
        }else if(localStorage && localStorage.length > 0 && localStorage.accessToken && (!localStorage.videoEngStatus || localStorage.videoEngStatus=='false' || localStorage.videoEngStatus == false)){
            history.push('/permision');
                 
     }else{
        setVideoUrl(url)
        setVideoTitle(slug)
        setVideoId(vId)
        setVideoDuration(vDu)
        setShow(true)
     }

}
const [videoSlug, setVideoSlug] = useState('')
const [show, setShow] = useState(false)
const [videoTitle, setVideoTitle] = useState('');
const [crowData, setCrowData] = useState()
const [crowStatus, setCrowStatus] = useState(true)
const getTopCrowsel=(data)=>{
    const getCrStories = data
    .filter(item => item.is_active) // Check if item is active
    .map((item) => {
      return {
        id: item.id,
        image: item.image,
        title: item.title,
        slug: item.slug,
        dateTime: item.created_at ? formatDate(item.created_at) : ''
      };
    });
    setCrowData(getCrStories);
}
const handleClose = () => setShow(false)
const handleTopClose = ()=> setCrowStatus(false)
const [dShow, setDShow] = useState(true)
const handleDClose = () => setDShow(false);

const [viDd, setVideoId] = useState(null)
const [vidoDu,setVideoDuration] = useState()
const [videoUrl, setVideoUrl] = useState('')
const getDateFormate =(value)=>{
    const currentDate = value
    const year = currentDate.getFullYear()
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2) // Adding 1 because getMonth returns zero-based index
    const day = ('0' + currentDate.getDate()).slice(-2)
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
}
const updateDateValue = ()=>{
    alert(dateValue)
    return getDateFormate(dateValue)
    //setSearch(event.target.value);
}
const getTopNewsData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/video/list/?tags=special`
    )
    .then((res) => {
    const latestStories =
        res.data.data &&
        res.data.data.length > 0 &&
        res.data.data.map((item) => {
 
        return {
            id: item.id,
            image: item.thumbnail,
            title: item.title,
            is_active:item.is_active,
            slug:'/video-wire-detail/'+item.slug,
            dateTime: item.created_at ? formatDate(item.created_at): ''
        }})
        setTopData(latestStories)
        getTopCrowsel(latestStories)

    })
 }
const LatestData =()=>{
   axios
   .get(
   `https://cms.iansnews.in/api/elastic/video/list/?top_news=true`
   )
   .then((res) => {
   const latestStories =
       res.data.data &&
       res.data.data.length > 0 &&
       res.data.data.slice(0, 6).map((item) => {
         let tgSt= item.tags.filter(it => it.name === 'Business')

       return {
           id: item.id,
           image: item.thumbnail,
           title: item.title,
           slug:'/video-wire-detail/'+item.slug,
           dateTime: item.created_at ? formatDate(item.created_at): ''
       }})
   setLatestData(latestStories)
   })
}
const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
const getCatgoryData=(obj)=>{
    if(obj != cateText){
        setCategoryName(obj)
        setCurrentPage(1)
        history.push('/video-wire/1');
    }
    //fetchData()
}
const searchData=(data)=>{
    setSearchText(data)
    history.push('/video-wire/'+data+'/1');
}
const [searchDateVal, SetSearchDateVal] = useState('')
const dateFilterSearch =(e)=>{
    SetSearchDateVal(e.target.value)

    //alert(e.target.value)
}
const fetchData = async (page) => {
   // alert(categoryId)
   setEngData()
//    if(page && page == 1){
//     SetSearchDateVal()
//    }

    page = page ? page: 1;
    let getApii = `https://cms.iansnews.in/api/elastic/video/list/?language=${langg}`;
    
    if(categoryId && categoryId !='all' && categoryId !='multimedia_videos' && categoryId !='raw_videos'){
        //cateText = `&tags=${cateText}`;
        getApii += `&tags=${categoryId}`;
    }
    if(inputSearch){
        getApii += `&query=${inputSearch}`;
    }
    if(searchDateVal){
        getApii += `&created_at_date=${searchDateVal}`;
    }
    getApii += `&page=${page}`;

    axios.get(getApii)
    .then(res => {
    
       
    
    const getStories =
    res.data.data &&
    res.data.data.length > 0 &&
    res.data.data

        .map(item => {
        return {
            byline: item.byline,
            content: item.short_desc,
            id: item.id,
            image: item.thumbnail,
            video_duration: item.video_duration ? item.video_duration : false,
            image_count: item.image_count,
            dateTime: item.created_at ? formatDate(item.created_at) : '',
            title: item.title,
            tags: item.tags,
            slug: '/video-wire-detail/' + item.slug,
            short_desc: item.short_desc,
            video_preview: item.video_preview ? item.video_preview : '',
            image_caption: item.image_caption,
        };
        });
        getStories.forEach((story) => {
            if (story.video_preview) {
              const videoElement = document.createElement('video');
              videoElement.src = story.video_preview;
              videoElement.preload = 'metadata';
      
              videoElement.onloadedmetadata = (event) => handleLoadedMetadata(event, story.id);
            }
          });
    setEngData(getStories)
    setLt(res.data.total_pages)
    setLastPage(res.data.total_pages)
    setRequestFlag(false)
    })
    .catch(err => {
    // console.log('Google api calendar error', err)
    })
}

const handleNextPage = () => {
    if(lastPage > currentPage){
    setCurrentPage(parseInt(currentPage) + 1)
    }
}

const [isVisible, setIsVisible] = useState(false);
const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth"
})}
const handlePrevPage = () => {
    if(currentPage > 1){
        setCurrentPage(currentPage - 1);
    }
}
const inputSData=(event)=>{
    setSearch(event.target.value);
  }
const onSubmit = (e) => {
    e.preventDefault()

      let path = '/video-search/'+inputSearch;
        //SetSHead('template-search')
        //window.reload()
        window.location.pathname=path;
        history.push(path);
    // console.log(conFom)
}
const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
        setIsVisible(true)
    } else {
        setIsVisible(false)
    }
}
const [isLoading, setIsLoading] = useState(false);
const clearFilter = ()=>{
    SetSearchDateVal('')
    setCurrentPage(1)
    setLanguagge('')
    let path = '';
    if(categoryId && categoryId !='short_videos'){
        path = '/video-wire/multimedia_videos/1';

    }else{
        path = '/video-wire/short_videos/1';

    }
    //SetSHead('template-search')
    //window.reload()
    window.location.pathname=path;
    history.push(path);
}
const handleSubmit = (title, id, VType) => {
    //e.preventDefault();
    let formData = {
       "content_id":  id ? id : '',
       "username": localStorage && localStorage.userName ? localStorage.userName : '',
       "content_type": VType,
       "content_title": title ? title : ''
    }
    axios.post('https://cms.iansnews.in/api/download_records/', formData)
      .then(response => {
        console.log('Success:', response.data);
        // Optionally, reset form fields
 
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  const videoDwlUrl=(url, type,id,title)=>{
    setIsLoading(true);
             if(type && type == 2){
                url = 'https://iansportalimages.s3.amazonaws.com/videos/'+url;
             }
             let modifiedUrl = 'videos/' + url.split('/').pop();
           // downloadResource(url, modifiedUrl);
           if (!modifiedUrl) modifiedUrl = url.split('\\').pop().split('/').pop();
           let updateUrl = url.replace("videos_watermark", "videos");
           fetch(updateUrl)
           .then(response => response.blob())
           .then(blob => {
             console.log('ÍANSSS',blob);
             if(blob && blob.size && blob.size > 1000){
               setIsLoading(false);
             }
               const fileSaver = FileSaver.saveAs(blob, modifiedUrl);
               fileSaver.onwriteend = function () {
               console.log('Download completed.');
               // alert();
              setIsLoading(false);
               };
               fileSaver.onerror = function () {
               console.error('Download failed.');
               };
           })
           .catch(e => console.error(e));
           if(type == 2){
            if(categoryId && categoryId == 'short_videos'){
                handleSubmit(title, id, 'Video - Short')
            }else{
                handleSubmit(title, id, 'Video - Raw')
            }
 
           }else if(type == 1){
            if(categoryId && categoryId == 'short_videos'){
                handleSubmit(title, id, 'Video - Short')

            }else{
                handleSubmit(title, id, 'Video - Edited')

            }

           }      
   }
   const [startPg, setStartPage] = useState(1)
const inputGSData=(event)=>{
    setStartPage(event.target.value)

  }
const GoToPage=(e)=>{
    e.preventDefault()
    setCurrentPage(startPg);
    let pathh = '/video-wire/'+cateText+'/'+startPg
    

    history.push(pathh)
    
}
const updateCrow=()=>{
   // alert();
   setCrowStatus(false)
}
useEffect(() => {
    if(categoryId){
        setCategoryName(categoryId)
    }
    // if(!localStorage || !localStorage.accessToken || !localStorage.videoEngStatus){

    //     history.push('/login');
    // }
    setTimeout(updateCrow, 3000);
    const intervalId = setInterval(() => {
        updateCrow(); // Call your function every 5 seconds
      }, 5000);
  
      // Cleanup the interval when the component unmounts
    //  return () => clearInterval(intervalId);
    let tempDate = new Date()
    
    console.log('test',tempDate)
   const getDD = getDateFormate(tempDate)
    //alert(getDD)
   // setDateValue(getDD)
    setCurrentPage(offset)
    setStartPage(offset)
    getTopNewsData()
    LatestData()
    if(currentPage == offset){
        fetchData(currentPage)
        setStartPage(currentPage)
    }
    scrollToTop()

    window.addEventListener("scroll", toggleVisibility)
    return () => window.removeEventListener("scroll", toggleVisibility)
//  .catch(err => { console.log('Google api calendar error', err) })
}, [currentPage, categoryId, search, langg, searchDateVal, selectedDate,offset])

return(
        <>
            <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>Indo-Asian News Service-{cateName ? cateName.toUpperCase() : ''}</title>
            <meta data-meta-title="IANS IN" name="keywords" content={''} />
            <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
            </Helmet>
            <Header currU={'/video-wire/multimedia_videos/1'}/>
            <HomeSlider/>
            <main id="main">
            <div className="container video-newsec">	
                 {/* <Top  title="TOP VIDEOS" data={topData} vTag={true}/> */}
            {engData && engData.length > 0 &&
               <section id="about" className="about">
               	
                        <div className="row">
                        {categoryId && categoryId == 'short_videos' &&
                            <VideoCatListShort clearFilter={clearFilter} langg={langg} setLanguagge={setLanguagge} searchDateVal={searchDateVal} onChange={onChange} selectedDate={selectedDate} categoryList={categoryList}/>

                        }
                        {categoryId && categoryId != 'short_videos' &&
                            <VideoCatList categoryId = {categoryId} clearFilter= {clearFilter} langg={langg} setLanguagge={setLanguagge} searchDateVal={searchDateVal} onChange={onChange} selectedDate={selectedDate} categoryList={categoryList}/>

                        }
                        
                        <div className="col-lg-9 tab-content" id="content1">
                            <div className="row post-loadmore-wrapper">
                                <div className="video-tab-sec">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className='nav-link' href={`/video-wire/just_in/1`}>Just In </a>
                                    </li>  
                                    <li className="nav-item">
                                        <a href={`/video-wire/multimedia_videos/1`} className={`${categoryId && categoryId == 'multimedia_videos' ? 'nav-link active' : 'nav-link'}`}>Multimedia Videos</a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink className={`${categoryId && categoryId == 'raw_videos' ? 'nav-link active' : 'nav-link'}`} to={`/video-wire/raw_videos/1`}>Raw Videos</NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <a className={`${categoryId && categoryId == 'short_videos' ? 'nav-link active' : 'nav-link'}`} href={`/video-wire/short_videos/1`}>Short Videos</a>
                                    </li>   
                                    <li className="nav-item">
                                        <a className='nav-link' href={`/video-wire/special_ians/1`}>Ians Special </a>
                                    </li>               
                                    </ul>
                                    
                                    <div className="search-form-allphoto">
                                    <form onSubmit={onSubmit}>            
                                        <input type="search" ref={inputRef} placeholder="Search...." onChange={inputSData} name="search" value={inputSearch}/>
                                        <span className="input-group-text border-0" onClick={onSubmit}><i className="bi bi-search" aria-hidden="true"></i></span>
                                    </form>                      
                                    </div>
                                </div>
                               { categoryId && categoryId =='multimedia_videos' &&  crowStatus &&   crowData && crowData.length > 0 && <Modal className="short-modal video-pop" show={dShow} onHide={handleDClose}>
                                    
                                    <Modal.Header closeButton>
                                    {/* <span>Download</span> */}
                                    Special Package
                                    </Modal.Header>
                                    <Modal.Body className='videoModal'>
                                        
                                    <TopVideoCrow  title="TOP VIDEOS" data={crowData} vTag={true}/>
                                     
                                    <Modal.Title className='shortModalTitle'>{videoTitle} </Modal.Title>

                                
                                        {/* <div className="download-vid">
                                            <span><a href={videoSlug} target="_blank" >Download Full Video <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a></span>
                                        </div> */}
                                    </Modal.Body>
                                        
                                </Modal> }                                  
                                <Modal className="short-modal" show={show} onHide={handleClose}>
                                    
                                    <Modal.Header closeButton>
                                   
                                    </Modal.Header>
                                    <Modal.Body className='videoModal'>
                                    <div className='short-video-df'>  
                                        <a alt="Download file" href="#" title="Download video"  onClick={()=>{
                                                videoDwlUrl(videoUrl, 1,viDd,videoTitle)
                                            }}>
                                            <span>Edited video</span> 
                                            <i alt="Download file" className="bi bi-download"></i>
                                        </a>   
                                        { vidoDu &&
                                            <a alt="Download file" href="#" title="Download video"  onClick={()=>{
                                                    videoDwlUrl(vidoDu, 2, viDd,videoTitle)
                                                }} style={{marginLeft:"20px"}}>
                                                <span>RAW video</span> 
                                                <i alt="Download file" className="bi bi-download"></i>
                                            </a>
                                        } 
                                    </div> 
                                    <video controlsList="nodownload" autoPlay controls loop src={videoUrl}>
                                        <source type="video/mp4" src={videoUrl}/>
                                    </video>
                                    {isLoading && 
                                    <div className="short-loader">
                                        <h1 className="lod-hed">Downloading....</h1>
                                        <div className="loader">
                                        </div>
                                    </div>
                                    }
                                    <Modal.Title className='shortModalTitle'>{videoSlug}</Modal.Title>

                                
                                        {/* <div className="download-vid">
                                            <span><a href={videoSlug} target="_blank" >Download Full Video <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a></span>
                                        </div> */}
                                    </Modal.Body>
                                        
                                </Modal>

                                    {engData && engData.length > 0 && engData.map((vidTemp, vt) => {  
                                        if(categoryId && categoryId == 'short_videos'){
                                            return (
                                                <div className="col-xl-3 col-lg-4 col-md-6 mb-3 short-vid-col sec-left" key={vt}>
                                                    <div className="short-thumb" onClick={()=>{
                                                            handleShow(vidTemp.video_preview, vidTemp.title,vidTemp.id,vidTemp.video_duration)
                                                        }}>

                                                        <img className="short-thumb-image" src={vidTemp.image}/>
                                                        <div className="des-title">{vidTemp.title}</div>
                                                        <div> {durations[vidTemp.id] && (
                                                                <div className="video-duration"> {durations[vidTemp.id]}</div>
                                                                    )}
                                                                    
                                                        </div>
                                                        <div className="label">{vidTemp.dateTime}</div>
                                                        <div className="mvp-vid-box-wrap-s" >
                                                            <i className="bi bi-play" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            )                                        
                                        }else{
                                            return (
                                                <div className="col-xl-4 col-lg-4 col-md-6 mb-3 vid-col sec-left video-d" key={vt}>
                                                    <NavLink title={vidTemp.title} to={vidTemp.slug}>
                                                        <div className="thumb w-100 album-thumb">
                                                            <img className="thumb-image w-100" src={vidTemp.image} />
                                                            <div className="text-l-gray des-title mt-3">{vidTemp.title} </div>
                                                            <div> {durations[vidTemp.id] && (
                                                                        <div className="video-duration"> {durations[vidTemp.id]}</div>
                                                                    )}</div>
                                                            <div className="label text-white label-photo">{vidTemp.dateTime}</div>
                                                            <div className="mvp-vid-box-wrap-s">
                                                                <i className="bi bi-play" aria-hidden="true"></i>
                                                            </div>
                                                            <div className="videotag">
                                                                <div className="videotag__container">
                                                                    {vidTemp.tags && vidTemp.tags.length > 0 && vidTemp.tags.map((tgg, t) => {
                                                                        return (
                                                                            <NavLink to={`/video-wire/${tgg.slug}/1`} className="videotag__item" key={t}>{tgg.name}</NavLink>
                                                                        )
                                                                    })}
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            ) 
                                        }                              
                                    })}
                                    {  lt && lt > 0 &&   engData && engData.length > 0 &&                             
                                        <nav aria-label="Page navigation example">
                                        <ul className="pagination">
                                        { currentPage && currentPage > 1 &&                              
                                            <li className="page-item" onClick={handlePrevPage}>
                                            <NavLink to={`/video-wire/${cateText}/${currentPage-1}`} className="page-link" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </NavLink>
                                            </li>
                                        }
                                            <li className="page-item"onClick={()=>{
                                                setCurrentPage(currentPage)
                                                }}>
                                        <a className="page-link active" href="#">{currentPage}</a></li>
{lt && lt > currentPage &&                                             <li className="page-item" onClick={()=>
                                            {
                                                setCurrentPage(parseInt(currentPage)+1)
                                            }}>
                                            <NavLink to={`/video-wire/${cateText}/${parseInt(currentPage) +1 }`} className="page-link">{parseInt(currentPage)+1}</NavLink>
                                            </li>}
{lt && lt > currentPage &&                                            <li className="page-item" onClick={()=>
                                            {
                                                setCurrentPage(parseInt(currentPage)+2)
                                            }}>
                                            <NavLink to={`/video-wire/${cateText}/${parseInt(currentPage) + 2 }`} className="page-link">{parseInt(currentPage)+2}</NavLink>
                                            </li>}
{lt && lt > currentPage &&                                            <li className="page-item" onClick={handleNextPage}>
                                            <NavLink to={`/video-wire/${cateText}/${parseInt(currentPage) + 1}`} className="page-link" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </NavLink>
                                            </li>}
                                            <li className="go_page"> Page 
                                                <input type="number" name="quantity" min={startPg} max={lastPage}  onChange={inputGSData} value={startPg}/>
                                                <i className="go_btn" aria-hidden="true" onClick={GoToPage}>GO</i>
                                             </li>                                              
                                        </ul>
                                        </nav>
                                    }                
                            </div>
                        </div>

                </div>
                
                </section>
                }                                

                </div>
            </main>              
            <Footer />  
        </>
)}
export default VideoWire